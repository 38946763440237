<template>
  <!-- s::MAIN -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <main v-else>
    <div>
      <div class="layout video-container">
<!--        <h1>-->
<!--          More Art<br>-->
<!--          Better Me-->
<!--        </h1>-->
        <video autoplay playsinline muted>
          <source :src="banners[0]" type="video/mp4">
        </video>
      </div>
    </div>
    <section class="section01">
      <div class="layout">
        <div class="art-box">
          <div class="title-box">
            <h2>
              ART &<br>
              ARTISTS
            </h2>
            <div class="button-tab">
              <button
                  v-for="artist in artists"
                  :key="artist.artistId"
                  :class="{ on: activeTab === artist.artistId }"
                  @click="setActiveTab(artist.artistId)"
              >{{ artist.artistNameEn }}
              </button>
            </div>
            <div class="button-box">
              <router-link to="/artist" :class="{ on: $route.path.startsWith('/artist') }">See More</router-link>
            </div>
          </div>
          <div class="slide-box">
            <div
                v-for="artist in artists"
                :key="artist.artistId"
                :id="artist.artistId"
                class="tab-cont"
                v-show="activeTab === artist.artistId"
            >
              <div class="inner">
                <router-link :to="{ name: 'ArtistDetail', params: { id: activeTab } }">
                  <div class="artists">
                    <div class="txt">
                      <h3>{{ artist.artistNameEn }}</h3>
                      <p>{{ artist.artistNameKo }}</p>
                    </div>
                    <div class="img-box"
                         :style="{ background: `url(${require('@/assets/images/main/artists_bg.png')}) top center no-repeat`, backgroundSize: 'cover' }">
                      <figure>
                        <img :src="artist.mainImageUrl">
<!--                        <img src="https://integer0916.cafe24.com/article21/images/main/artists1.png" alt=""/>-->
                      </figure>
                    </div>
                  </div>
                </router-link>
                <!-- Swiper -->
                <div class="slide-wrap">
                  <div :class="`swiper mySwiper-${artist.artistId}`">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="item in artist.artWorks" :key="item.id">
                        <router-link :to="{ name: 'Artwork', params: { id: artist.artistId, artWorkId: item.id } }">
                          <img :src="item.watermarkedImageUrl"/>
                          <div class="txt">
                            <h4>{{ item.title }}</h4>
                            <p>{{ item.material }}</p>
                            <span>{{ item.year }}</span>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div :class="`swiper-scrollbar mySwiper-scrollbar-${artist.artistId}`"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section03">
      <div class="layout">
        <h2>ARTICLE</h2>
        <div class="banner">
          <router-link to="/article/special">
            <img :src="banners[3]" alt=""/>
            <h3></h3>
          </router-link>
        </div>
      </div>
    </section>
    <section class="section04">
      <div class="layout">
        <ul>
          <li>
            <router-link to="/article/exhibition">
              <img :src="banners[4]" alt=""/>
              <h3></h3>
            </router-link>
          </li>
          <li>
            <router-link to="/article/event">
              <img :src="banners[5]" alt=""/>
              <h3></h3>
            </router-link>
          </li>
          <li>
            <router-link to="/article/news">
              <img :src="banners[6]" alt=""/>
              <h3></h3>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </main>
  <!-- e::MAIN -->
</template>

<script setup>
import {nextTick, onMounted, ref} from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import {apiClient} from '@/services/auth-header';
import router from "@/router";
import {useAuthStore} from "@/store/auth.module";

const authStore = useAuthStore();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const artists = ref([]);
const banners = ref([]);
const products = ref([]);
const activeTab = ref(1);
let isMobile = ref(false);
let perView = ref(4);

// Swiper 초기화 함수
const initSwiper = async () => {
  await nextTick();
  if(isMobile.value){
    perView.value = 2;
  }
  artists.value.forEach((tab) => {
    new Swiper(`.mySwiper-${tab.artistId}`, {
      observer: true,
      observeParents: true,
      spaceBetween: 18,
      slidesPerView: perView.value,
      loop: false,
      freeMode: true,
      scrollbar: {
        el: `.mySwiper-scrollbar-${tab.artistId}`,
        draggable: true,
      },
      autoplay: {
        delay: 10000,
      },
    });
  });
};

// 탭 변경 함수
const setActiveTab = (tabId) => {
  activeTab.value = tabId;
  initSwiper(); // 탭 변경 시 Swiper 초기화
};

// 데이터 로드 및 초기화
const loadArtists = async () => {
  try {
    const response = await apiClient.get('/home');
    artists.value = response.data.artists;
    banners.value = response.data.banners;
    products.value = response.data.products;
    localStorage.setItem("menu", JSON.stringify(response.data.menu));
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  } catch (err) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    await initSwiper();
  }
};

const goArticler = () => {
  alert("서비스 준비중입니다.");
};

// SNS 로그인 함수
const snsLogin = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = {"accessToken": urlParams.get('accessToken')};
  const response = await authStore.snsLogin(accessToken);
  const role = response.roles[0];
  const redirectPath = role === 'ROLE_ADMIN' ? '/admin' : '/';
  await router.push(redirectPath);
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  loadArtists();
  checkMobile();
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('accessToken')) {
    snsLogin();
  }
});

</script>

<style scoped>
.video-container {
  display: flex;
  justify-content: center;
  /*height: 100vh;*/
}

video {
  width: 1200px;  /* 원하는 너비 설정 */
  /*margin-bottom: 150px;*/
}
.section02 .layout li {
  cursor: pointer;
}
</style>